import React, { useState, useCallback, useEffect } from "react"
import { Link } from "gatsby"
import Headroom from "react-headroom"

const Header = () => {
  const [mainMenuOpen, setmainMenuOpen] = useState(false)

  const mainMenuOpenToggle = useCallback(() => {
    setmainMenuOpen(state => !state)
  }, [])

  useEffect(() => {
    document.body.style.overflow = mainMenuOpen ? "hidden" : "visible"
  }, [mainMenuOpen])
  return (
    <>
      <Headroom disableInlineStyles>
        <div className="header-main">
          <div className="container">
            <div className="flex-row align-items-center no-margin">
              <div className="col-4">
                <div className="header-logo">
                  <Link to="/">
                    <svg fill="none" viewBox="0 0 26 36" width="26" height="36">
                      <path
                        fill="#EA502A"
                        fillRule="evenodd"
                        d="M11.971 24.405c-1.228-1.229-3.02-1.372-4.486-.408l-4.69 3.105a4.683 4.683 0 106.478 6.478l3.104-4.69c.964-1.465.82-3.259-.406-4.485z"
                        clipRule="evenodd"
                      />
                      <path
                        fill="#EDEFF9"
                        fillRule="evenodd"
                        d="M14.422 0c5.88 0 10.692 4.812 10.692 10.691 0 5.88-4.81 10.691-10.692 10.691H9.526c-1.16 0-2.16.316-3.134.96l-2.774 1.835a1.959 1.959 0 01-2.95-1.69V1.962c0-1.109.901-1.974 2.005-1.96L14.423 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="col-8">
                <nav className={`${mainMenuOpen ? "menu-open" : ""}`}>
                  <button
                    type="button"
                    className="hamburger"
                    onClick={mainMenuOpenToggle}
                  >
                    <span />
                  </button>
                  <ul className="menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about/">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact/">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </>
  )
}
export default Header
