import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import scrollReveal from "../utils/scrollReveal"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const loadHandler = useCallback(() => {
    Array.from(document.querySelectorAll("[data-watch-visibility]")).forEach(
      el => {
        if (scrollReveal(el)) el.classList.add("visible")
        // else el.classList.remove('visible');
      }
    )
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      // loadHandler();
      window.addEventListener("scroll", loadHandler)
      return () => window.removeEventListener("load", loadHandler)
    }

    return null
  }, [loadHandler])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <div
        className="loader"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9999999,
          backgroundColor: "#fff",
          opacity: loading ? "1" : "0",
          visibility: loading ? "visible" : "hidden",
          transition: "all 0.8s ease",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg fill="none" viewBox="0 0 91 132" width="91" height="132">
          <path
            fill="#EA502A"
            fillRule="evenodd"
            d="M42.014 89.947C37.496 85.43 30.903 84.9 25.512 88.45L8.26 99.868a17.331 17.331 0 00-2.792 2.262c-6.726 6.726-6.726 17.633 0 24.358 6.726 6.726 17.633 6.726 24.359 0a17.102 17.102 0 002.262-2.791l11.418-17.248c3.55-5.39 3.02-11.99-1.492-16.502z"
            clipRule="evenodd"
          />
          <path
            fill="#380D80"
            fillRule="evenodd"
            d="M51.028.177c21.627 0 39.326 17.7 39.326 39.327S72.661 78.83 51.028 78.83H33.016c-4.265 0-7.947 1.161-11.526 3.531l-10.204 6.75A7.204 7.204 0 01.433 82.896V7.39C.434 3.312 3.749.13 7.81.183c15.136 0 28.077-.006 43.219-.006z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />

        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.10/lottie_light.min.js"
          integrity="sha512-LItScikRjzNT/O9IRU7gna3P07HhiDU/hMEYeEDw+d36xsLL0JV+avL43fQGKTUek32yikuVnbcjCWtsezYQYw=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
      <div style={{ overflowX: "hidden" }}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
