import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <>
      <footer>
        <Link to="/" className="footer-logo-area">
          <svg fill="none" viewBox="0 0 26 36" width="26" height="36">
            <path
              fill="#EA502A"
              fillRule="evenodd"
              d="M11.971 24.405c-1.228-1.229-3.02-1.372-4.486-.408l-4.69 3.105a4.683 4.683 0 106.478 6.478l3.104-4.69c.964-1.465.82-3.259-.406-4.485z"
              clipRule="evenodd"
            />
            <path
              fill="#EDEFF9"
              fillRule="evenodd"
              d="M14.422 0c5.88 0 10.692 4.812 10.692 10.691 0 5.88-4.81 10.691-10.692 10.691H9.526c-1.16 0-2.16.316-3.134.96l-2.774 1.835a1.959 1.959 0 01-2.95-1.69V1.962c0-1.109.901-1.974 2.005-1.96L14.423 0z"
              clipRule="evenodd"
            />
          </svg>

          <img src="/img/circular-text.svg" alt="" className="circular-text" />
        </Link>
        <div className="container">
          <div className="footer-links">
            <span className="hidden-mobile">
              <Link to="/">
                <svg fill="none" viewBox="0 0 26 36" width="26" height="36">
                  <path
                    fill="#EA502A"
                    fillRule="evenodd"
                    d="M11.971 24.405c-1.228-1.229-3.02-1.372-4.486-.408l-4.69 3.105a4.683 4.683 0 106.478 6.478l3.104-4.69c.964-1.465.82-3.259-.406-4.485z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#EDEFF9"
                    fillRule="evenodd"
                    d="M14.422 0c5.88 0 10.692 4.812 10.692 10.691 0 5.88-4.81 10.691-10.692 10.691H9.526c-1.16 0-2.16.316-3.134.96l-2.774 1.835a1.959 1.959 0 01-2.95-1.69V1.962c0-1.109.901-1.974 2.005-1.96L14.423 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </span>
            <span>
              <Link to="/">Home</Link>
            </span>
            <span>
              <Link to="/about/">About</Link>
            </span>
            <span>
              <Link to="/contact/">Contact</Link>
            </span>
          </div>
          <div className="flex-row">
            <div className="col col-5">
              <div className="single-footer-block">
                <h3>Write</h3>
                <a href="mailto:hello@thepotatostudio.co.in">
                  hello@thepotatostudio.co.in
                </a>
              </div>
              <div className="single-footer-block">
                <h3>Follow</h3>
                <div className="footer-social">
                  <a
                    href="https://www.behance.net/thepotatostudio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="30" height="19" viewBox="0 0 30 19" fill="none">
                      <path
                        fill="#fff"
                        d="M12.163 8.264c.602-.302 1.057-.64 1.369-1.004.554-.656.828-1.526.828-2.605 0-1.05-.274-1.946-.82-2.699C12.625.731 11.08.104 8.892.071H.233v17.68h8.075c.91 0 1.753-.079 2.532-.238.78-.162 1.454-.457 2.025-.889a4.902 4.902 0 001.269-1.39c.532-.83.799-1.77.799-2.819 0-1.015-.234-1.88-.698-2.59-.47-.71-1.158-1.23-2.072-1.56zM3.805 3.142h3.901c.857 0 1.564.092 2.119.275.642.267.963.809.963 1.635 0 .742-.245 1.26-.73 1.552-.487.292-1.12.439-1.895.439H3.805V3.142zm6.169 11.23c-.432.208-1.039.311-1.817.311H3.805V9.968h4.412c.769.006 1.367.107 1.795.298.761.344 1.14.975 1.14 1.898 0 1.087-.392 1.82-1.178 2.207zM26.444.896h-7.68v2.2h7.68v-2.2zM29.115 9.58c-.16-1.024-.51-1.925-1.058-2.702-.6-.881-1.361-1.526-2.288-1.934-.922-.41-1.961-.615-3.117-.613-1.942 0-3.519.607-4.739 1.813-1.216 1.209-1.825 2.947-1.825 5.213 0 2.416.672 4.162 2.025 5.233 1.346 1.073 2.902 1.608 4.667 1.608 2.137 0 3.8-.636 4.987-1.905.76-.801 1.189-1.59 1.283-2.364h-3.538c-.205.383-.442.682-.714.9-.493.398-1.135.597-1.922.597-.748 0-1.383-.165-1.912-.493-.874-.525-1.33-1.446-1.389-2.757h9.656c.015-1.13-.021-1.997-.116-2.595zm-9.453.34c.127-.85.436-1.525.925-2.023.49-.497 1.182-.747 2.068-.75.818 0 1.501.235 2.057.704.55.473.86 1.16.923 2.068h-5.973z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://dribbble.com/thepotatostudio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path
                        fill="#fff"
                        d="M22.756 6.809a11.95 11.95 0 00-4.367-4.367C16.55 1.369 14.543.832 12.366.832c-2.178 0-4.185.537-6.024 1.61a11.947 11.947 0 00-4.367 4.367C.902 8.648.365 10.656.365 12.832c0 2.178.537 4.185 1.61 6.024a11.95 11.95 0 004.367 4.367c1.839 1.073 3.846 1.61 6.023 1.61 2.177 0 4.185-.537 6.024-1.61a11.947 11.947 0 004.367-4.367c1.073-1.839 1.61-3.847 1.61-6.024s-.537-4.185-1.61-6.023zm-10.39-4.226c2.583 0 4.838.854 6.765 2.562l-.195.273c-.078.11-.274.32-.586.633-.313.312-.654.61-1.024.89-.37.282-.872.602-1.508.962-.635.36-1.312.674-2.03.945-1.199-2.208-2.48-4.198-3.845-5.969.824-.198 1.63-.296 2.422-.296zm-8.032 3.89c1-1.26 2.219-2.23 3.656-2.906 1.292 1.718 2.563 3.687 3.813 5.906-3.167.833-6.323 1.25-9.469 1.25.334-1.573 1-2.99 2-4.25zM2.803 16.528a10.055 10.055 0 01-.688-3.695c0-.146.006-.256.016-.329 3.771 0 7.276-.484 10.516-1.453.333.646.61 1.224.828 1.735-.042.02-.11.044-.203.07a9.657 9.657 0 01-.204.055l-.578.203c-.396.146-.914.396-1.555.75-.64.354-1.32.776-2.039 1.265-.718.49-1.46 1.139-2.226 1.946a13.1 13.1 0 00-1.93 2.601 10.261 10.261 0 01-1.937-3.148zm9.562 6.555c-2.437 0-4.614-.782-6.531-2.344l.234.172c.365-.802.901-1.573 1.61-2.313.708-.74 1.39-1.336 2.047-1.789.656-.453 1.37-.88 2.14-1.281.771-.401 1.297-.657 1.579-.766.28-.109.505-.195.671-.258l.031-.015h.032c1.02 2.677 1.75 5.27 2.187 7.78a10.244 10.244 0 01-4 .814zm8.626-4.711a10.24 10.24 0 01-2.89 2.96c-.428-2.395-1.094-4.838-2-7.328 2.072-.323 4.202-.171 6.39.453a10.113 10.113 0 01-1.5 3.915zm1.468-5.68c-.104-.02-.234-.044-.39-.07l-.571-.094c-.224-.037-.474-.07-.75-.101a25.75 25.75 0 00-2.961-.172c-.364 0-.747.01-1.148.03-.401.021-.79.058-1.164.11a1.861 1.861 0 01-.117-.258 5.417 5.417 0 01-.102-.273 102.94 102.94 0 00-.688-1.485 16.023 16.023 0 002.056-1.007c.65-.38 1.17-.72 1.562-1.016.39-.297.76-.612 1.11-.945.348-.334.575-.565.679-.696.104-.13.203-.258.297-.382l.016-.016c1.52 1.854 2.297 3.99 2.328 6.406l-.157-.031z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/the_potatostudio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path
                        fill="#fff"
                        d="M17.742.833H6.988A6.63 6.63 0 00.365 7.456v10.753a6.63 6.63 0 006.623 6.623h10.754a6.63 6.63 0 006.623-6.623V7.456A6.63 6.63 0 0017.742.833zm4.494 17.376a4.499 4.499 0 01-4.494 4.494H6.988a4.499 4.499 0 01-4.493-4.494V7.456a4.499 4.499 0 014.493-4.494h10.754a4.499 4.499 0 014.494 4.494v10.753z"
                      />
                      <path
                        fill="#fff"
                        d="M12.365 6.648a6.191 6.191 0 00-6.184 6.185 6.191 6.191 0 006.184 6.184 6.191 6.191 0 006.184-6.184 6.191 6.191 0 00-6.184-6.185zm0 10.239a4.06 4.06 0 01-4.055-4.055 4.06 4.06 0 014.055-4.054 4.06 4.06 0 014.055 4.054 4.06 4.06 0 01-4.055 4.055zM18.81 4.843c-.41 0-.814.166-1.103.457-.292.29-.459.693-.459 1.104 0 .41.168.814.459 1.105.29.29.692.457 1.103.457.411 0 .813-.168 1.104-.457.291-.291.457-.694.457-1.105 0-.411-.166-.815-.457-1.104a1.568 1.568 0 00-1.104-.457z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/The-Potato-Studio-110083491362556/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="29" height="29" fill="none" viewBox="0 0 29 29">
                      <path
                        fill="#17181C"
                        d="M28.826 14.317c0-7.732-6.268-14-14-14s-14 6.268-14 14c0 6.987 5.12 12.78 11.813 13.83v-9.784H9.084v-4.046h3.555v-3.085c0-3.509 2.09-5.447 5.288-5.447 1.531 0 3.134.274 3.134.274v3.445h-1.766c-1.739 0-2.281 1.08-2.281 2.186v2.627h3.883l-.621 4.046h-3.262v9.784c6.693-1.05 11.812-6.843 11.812-13.83z"
                      />
                      <path
                        fill="#fff"
                        d="M20.276 18.363l.62-4.047h-3.882V11.69c0-1.107.542-2.186 2.281-2.186h1.765V6.059s-1.602-.274-3.133-.274c-3.198 0-5.288 1.938-5.288 5.447v3.084H9.084v4.047h3.555v9.783a14.096 14.096 0 004.375 0v-9.783h3.262z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://thepotatostudio.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="40" height="23" fill="none" viewBox="0 0 40 23">
                      <path
                        fill="#fff"
                        d="M22.75 11.505c0 6.26-4.92 11.334-10.989 11.334-6.069 0-10.99-5.074-10.99-11.334C.771 5.246 5.691.171 11.761.171s10.99 5.075 10.99 11.334zM34.806 11.505c0 5.892-2.46 10.67-5.495 10.67s-5.495-4.778-5.495-10.67S26.276.835 29.311.835s5.495 4.777 5.495 10.67M39.738 11.505c0 5.278-.865 9.559-1.933 9.559-1.067 0-1.932-4.28-1.932-9.559 0-5.279.865-9.559 1.932-9.559s1.933 4.28 1.933 9.559z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/the-potato-studio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="24" height="23" fill="none" viewBox="0 0 24 23">
                      <path
                        fill="#fff"
                        d="M3.526.603C1.82.603.706 1.723.706 3.195c0 1.44 1.081 2.592 2.755 2.592h.032c1.74 0 2.822-1.152 2.822-2.592C6.282 1.723 5.233.603 3.526.603zM1 7.834h4.987v15.004H1V7.834zM18.23 7.483c-2.69 0-4.495 2.528-4.495 2.528V7.835H8.748V22.84h4.987V14.46c0-.448.033-.896.164-1.217.36-.896 1.181-1.824 2.559-1.824 1.805 0 2.526 1.376 2.526 3.393v8.027h4.987v-8.603c0-4.609-2.46-6.753-5.741-6.753z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="made-in-india">
                <div>
                  <img src="/img/india.svg" alt="India" />

                  <svg fill="none" viewBox="0 0 18 15">
                    <path
                      fill="#FF4B4B"
                      stroke="#FF4B4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.898"
                      d="M15.266 2.413a3.83 3.83 0 00-5.417 0l-.738.738-.739-.738A3.83 3.83 0 002.955 7.83l.738.738 5.418 5.417 5.417-5.417.738-.738a3.83 3.83 0 000-5.417z"
                    />
                  </svg>
                  <p>Based in India</p>
                </div>
              </div>
            </div>
            <div className="col col-5">
              <div className="single-footer-block">
                <h3>Voice</h3>
                <a href="tel:+918968464636">+91 96202 32209</a>
              </div>

              <p className="copyright hidden-mobile">
                © 2021 THE POTATO STUDIO
              </p>
            </div>
          </div>

          <p className="copyright visible-mobile">© 2021 THE POTATO STUDIO</p>
        </div>
      </footer>
    </>
  )
}
export default Footer
